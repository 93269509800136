import React, { Component } from 'react';
import { lang } from'../Helpers/lang';
import { useParams, useNavigate, Link, withRouter } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Select from 'react-select';
import $ from 'jquery';
import {scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl, mediaUrl} from'../Config/Config';
import axios from 'axios';


class Edit extends Component {	
	constructor(props)
	{
		super(props);
		const id = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;
		this.state = {
		  list_id : id,
	      login_type:'',
	      show_count_yes:true,
	      show_count_no:false,
	      show_count:'yes',
	      name:'',
	      module_type:'',
	      module_type_value:'',
		  status_type:'',
		  status_type_value:'',
	      priorty:'',
		  content:'',
		  image_preview:'',
		  image_name:'',
		  image:'',
	      name_arr:[],
	      module_type_arr:[],
	      module_type_value_arr:[],
		  status_type_arr:[],
		  status_type_value_arr:[],
	      priorty_arr:[],
		  content_arr:'',
		  image_preview_arr:'',
		  image_name_arr:'',
	      showlayout: false,
	      select_options:'',
	      select_options_arr:[],
		  status_options:'',
	      status_options_arr:[],
	      selectedvalue: '',
          selectedOption:{ value: 'active', label: 'Active'},
          Loading:false,
		  show_count_arr:[],
		  image_arr:[],
          editindex:'',
          field_ids:[],
		  remove_ids:'',
		  login_type:'',
		  image_name_single:'',
		  tooltip:'',
		  tooltip_arr:[],
		};
    this.deletesinglefield = this.deletesinglefield.bind(this);
	var qs = require('qs');
	var listid = this.state.list_id;

	axios.get(apiUrl+"shortcuticons/listdetail?id="+listid).then(res => {
		const formdata = res.data.data;

		console.log(formdata,'formdata');
		this.setState({login_type : {value:formdata.usertype, label:formdata.usertype}, showlayout: true, login_type_value: formdata.usertype});
		if(formdata.field){
			this.setState({name_arr: formdata.field});
		}
		if(formdata.priority){
			this.setState({priorty_arr: formdata.priority});
		}
		
		if(formdata.tooltip){
			this.setState({tooltip_arr: formdata.tooltip});
		}

		if(formdata.select_option){
		
			this.setState({module_type_value_arr : formdata.select_option});
		}
		if(formdata.status_option){
		
			this.setState({status_type_value_arr : formdata.status_option});
		}

		
		if(formdata.image){
			this.setState({image_name_arr: formdata.image, image_arr: formdata.image});
		}
		if(formdata.column_id){
			this.setState({field_ids: formdata.column_id});
		}	
	}); 

 }
	
	componentDidMount() {
		  	if(localStorage.getItem("admin_id") === 'undefined' && localStorage.getItem('admin_id') === '' && localStorage.getItem('admin_id') === null){
			
			this.props.navigate('/shortcuticon')
		}
  }
	handleInputChange = (event) =>{
    const { name,value } = event.target;
    this.setState({[name]: value})
     if(name === 'form_column'){
       this.setState({form_one_column: !this.state.form_one_column});
       this.setState({form_two_column: !this.state.form_two_column});
    }
    if(name === 'field_required'){
        this.setState({field_required_checked: !this.state.field_required_checked});
    }
    if(name == 'select_options'){
        this.setState({select_options:value})
    }
  }

  handleChange = login_type => {
	this.setState({login_type})
	this.setState({  login_type_value : login_type.value});
};

 
 handleFormSubmit = () => {

				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
		
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					list_id : this.state.list_id,
					field_ids : formPayload.field_ids,
					user: formPayload.login_type_value,
					name_arr: formPayload.name_arr,
					module_type_value_arr: formPayload.module_type_value_arr,
					status_type_value_arr:formPayload.status_type_value_arr,
					priorty_arr: formPayload.priorty_arr,
					tooltip_arr: formPayload.tooltip_arr,
					removeid: formPayload.remove_ids,
				};
				//this.props.getEditForm(qs.stringify(postObject));
				
				let formData = new FormData();
		
				for(let k in postObject) {
					formData.append(k, postObject[k]);
				}
				var filesdata = this.state.image_arr;
				formPayload.name_arr.forEach((row, index) => {
					filesdata.forEach((image, i) => {
						if(index == i){
							formData.append(`update_image[${index}]`, image);
						}							
					});
				});
				axios.post(apiUrl+"shortcuticons/edit",formData,config).then(res => {
					if(res.data.status === "success"){
							$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
									function() {
										//this.props.navigate('/shortcuticon');
										$('.success_message').html(this.props.history.push('/shortcuticon'));
									}
									.bind(this),
									3000
								);
						}else{
							$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
							
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
						}
					});

	}

	validateForm=()=>
	{
		let errors = 0;
		if (!this.state.name) {
			errors++;
			$('.error_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(this.state.name){		
			$('.error_name').html('');
		}
		if (!this.state.module_type_value) {
			errors++;
			$('.error_module_type_value').html('<span class="errorspan">Please select the field</span>');
		}else if(this.state.module_type_value){		
			$('.error_module_type_value').html('');
		}

		if (!this.state.status_type_value) {
			errors++;
			$('.error_status_type_value').html('<span class="errorspan">Please select the field</span>');
		}else if(this.state.status_type_value){		
			$('.error_status_type_value').html('');
		}

		if (!this.state.priorty) {
			errors++;
			$('.error_priorty').html('<span class="errorspan">Please fill the field</span>');
		}else if(this.state.priorty){		
			$('.error_priorty').html('');
		}

		
		if(!this.state.tooltip) {
			errors++;
			$('.error_tooltip').html('<span class="errorspan">Please fill the field</span>');
		}else if(this.state.tooltip){		
			$('.error_tooltip').html('');
		}			
		console.log(errors,'errorserrors')
		if(errors>0) {			
			return false;
		}else{
			return true;
		}
	}

  Addtolayout=()=>{
		
		if(this.validateForm())
		{
			console.log('Addtolayout')
			var Index = this.state.editindex;
			let fieldname = [...this.state.name_arr];
			let fieldtype = [...this.state.module_type_value_arr];
			let statustype = [...this.state.status_type_value_arr];
			let priority = [...this.state.priorty_arr];
			let tooltip = [...this.state.tooltip_arr];
			let image = [...this.state.image_arr]; 
			

			if(Index !==''){
				fieldname.splice(Index, 1,this.state.name);	
				fieldtype.splice(Index, 1,this.state.module_type_value);
				statustype.splice(Index, 1,this.state.status_type_value);
				priority.splice(Index, 1,this.state.priorty);
				tooltip.splice(Index, 1,this.state.tooltip);
				image.splice(Index,1,this.state.image);
				this.setState({name_arr: fieldname,module_type_value_arr: fieldtype,priorty_arr:priority,  tooltip_arr:tooltip,image_arr:image,status_type_value_arr:statustype});
			}else{

				this.setState({
					name_arr: this.state.name_arr.concat(this.state.name), 
					module_type_value_arr: this.state.module_type_value_arr.concat(this.state.module_type_value),
					status_type_value_arr: this.state.status_type_value_arr.concat(this.state.status_type_value),
					priorty_arr: this.state.priorty_arr.concat(this.state.priorty),
					tooltip_arr: this.state.tooltip_arr.concat(this.state.tooltip),
					
					image_arr: this.state.image_arr.concat(this.state.image)
					});
				
				
			}
				this.setState({showlayout: true},function(){
				this.setState({name:'',module_type:'',module_type_value:'',status_type:'',status_type_value:'',priorty:'',content:'',editindex:'',tooltip:'',image_name_single:'',image_preview:''});
				this.getformlayout();
				scrollToTop();
				$('#addbutton').html('Add');
			});
		}
  	}

  	validateFormfield() {
		const {form_field_name,form_input_type_value,field_priorty,select_options,field_tooltip} = this.state;
		let errors = 0;
	
		if (!form_field_name) {
			errors++;
			$('.errorfieldname').html('<span class="errorspan">Please fill the field</span>');
		}else if(form_field_name){
			$('.errorfieldname').html('');
		}


		if (!form_input_type_value) {
			errors++;
			$('.errortype').html('<span class="errorspan">Please select input type</span>');
		}else if(form_input_type_value){
			$('.errortype').html('');
		}

		if(!field_priorty){
		errors++;
			$('.errorpriority').html('<span class="errorspan">Please fill the field</span>');
		}else if(field_priorty){
			$('.errorpriority').html('');
		}

		if(form_input_type_value == 'select'){
			if(!select_options){
				errors++;
				$('.erroroptions').html('<span class="errorspan">Please fill the field</span>');
			}else if(select_options){
				$('.erroroptions').html('');
			}
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

    }

       componentWillReceiveProps(Props){

    	   if(Props.formdetail !== this.props.formdetail){
    		if(Object.keys(Props.formdetail).length > 0){
				this.setState({Loading:false});
					//console.log(Props.carlist);
					if(Props.formdetail[0].status === "success"){

						const formdata = Props.formdetail[0].formdata;
						this.setState({form_name : formdata.formname});
						if(formdata.formcolumn == 1){
							this.setState({form_one_column: true,form_two_column: false});
						}else{
							this.setState({form_two_column: true,form_one_column:false})
						}
						if(formdata.formstatus === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
						}
					
					  	if(formdata.field){
					  		this.setState({name_arr: formdata.field,showlayout:true});
					  	}

					  	if(formdata.priority){
					  		this.setState({field_priorty_arr: formdata.priority});
					  	}

						if(formdata.tooltip){
							this.setState({field_tooltip_arr: formdata.tooltip});
						}
					

					  	if(formdata.required){
					  		this.setState({field_required_arr : formdata.required});
					  	}
					  	if(formdata.fieldtype){
					  		this.setState({form_input_type_value_arr: formdata.fieldtype});
					  	}
					  	if(formdata.field_id){
					  		this.setState({field_ids: formdata.field_id});
					  	}
					  	if(formdata.select_option){
					  		this.setState({select_options_arr: formdata.options});
					  	}
					}
    		}
    	}

    if(Props.formedit !== this.props.formedit){

    		if(Object.keys(Props.formedit).length > 0){

    			this.setState({ Loading: false });

    			if(Props.formedit[0].status === "success"){
    				

						const formdata = Props.formedit[0].formdata;
						this.setState({form_name : formdata.formname});
						if(formdata.formcolumn == 1){
							this.setState({form_one_column: true,form_two_column: false});
						}else{
							this.setState({form_two_column: true,form_one_column:false})
						}
						if(formdata.formstatus === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
						}
					
					  	if(formdata.field){
					  		this.setState({name_arr: formdata.field,showlayout:true});
					  	}
					  	if(formdata.priority){
					  		this.setState({field_priorty_arr: formdata.priority});
					  	}
						
					
						if(formdata.tooltip){
							this.setState({field_tooltip_arr: formdata.tooltip});
						}

					  	if(formdata.required){
					  		this.setState({field_required_arr : formdata.required});
					  	}
					  	if(formdata.fieldtype){
					  		this.setState({form_input_type_value_arr: formdata.fieldtype});
					  	}
					  	if(formdata.field_id){
					  		this.setState({field_ids: formdata.field_id});
					  	}
					  	if(formdata.select_option){
					  		this.setState({select_options_arr: formdata.options});
					  	}

					  	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.formedit[0].message+'</h3></div>');
	                	scrollToTop();
	    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					
    			}else{
    			$('.success_message').html('<div class="status_sucess"><h3>'+ Props.formedit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    		 }
    	}
    }
  }
  deletesinglefield(Index, id){
      let fieldname = [...this.state.name_arr];
      let fieldtype = [...this.state.module_type_value_arr];
      let statustype = [...this.state.status_type_value_arr];
	  
      let showcount = [...this.state.show_count_arr];
      let fieldpriorty = [...this.state.priorty_arr]; 
      let fieldtooltip = [...this.state.tooltip_arr]; 
      let fieldids = [...this.state.field_ids]; 
      fieldname.splice(Index, 1);
      fieldtype.splice(Index, 1);
      fieldpriorty.splice(Index, 1);
      fieldtooltip.splice(Index, 1);
      fieldids.splice(Index, 1);
      this.setState({name_arr: fieldname,
		module_type_value_arr: fieldtype,
		status_type_value_arr: statustype,
		show_count_arr:showcount,priorty_arr:fieldpriorty,
		tooltip_arr:fieldtooltip,
		field_ids:fieldids});
	  this.setState(prevState => ({
		remove_ids: [...prevState.remove_ids, id]
	  }))
  }
  editsinglefield = (index) => {

		$('.error_name').html('');
		$('.error_module_type_value').html('');
		$('.error_status_type_value').html('');
		$('.error_priorty').html('');
		$('.error_tooltip').html('');
  		this.setState({name:this.state.name_arr[index],priorty:this.state.priorty_arr[index],tooltip:this.state.tooltip_arr[index],editindex: index});

  		var fieldtype = this.state.module_type_value_arr[index];
  		  var contr =[];
  		  var contrstatus =[];

		  for(let i=0; i<lang.common.shortcuticon_options.length; i++){
		  if(lang.common.shortcuticon_options[i].value == this.state.module_type_value_arr[index]){
		 	 contr.push(lang.common.shortcuticon_options[i])
		  }
		  }
		  this.setState({module_type: contr,module_type_value: this.state.module_type_value_arr[index]})

		for(let i=0; i<lang.common.status_option.length; i++){
			if(lang.common.status_option[i].value == this.state.status_type_value_arr[index]){
				contrstatus.push(lang.common.status_option[i])
			}
		}
		this.setState({status_type: contrstatus,status_type_value: this.state.status_type_value_arr[index]});			

  		if(this.state.show_count_arr[index] == 'yes'){
  			this.setState({show_count : 'yes'})
  		}else{
			this.setState({show_count_yes :'no'})
  		}
  		if(this.state.select_options_arr[index]!==''){
  			this.setState({select_options:this.state.select_options_arr[index]});
  		}
		//   if(this.state.status_options_arr[index]!==''){
		// 	this.setState({status_options:this.state.status_options_arr[index]});
		// }
		if(this.state.image_name_arr[index]!=='' && this.state.image_name_arr[index]!==null){
			this.setState({image_name_single:this.state.image_name_arr[index],image_preview:''});
		}else{
			this.setState({image_name_single:'',image_preview:''});
		}
		$('#addbutton').html('Update');
  }
  getformlayout(){
      var form_field_value = this.state.name_arr;
      if (Object.keys(form_field_value).length > 0) {
      const formlayout = form_field_value.map(
      (datas, Index) => {
      let indexdata = Index+1;
      return (
            <div key={Index+'list'}>
            <li className="sortablecontainer-module-list">
            {/* <i class="fa fa-chevron-right" aria-hidden="true"></i> */}
			<div className='sortablecontainer-module-left'>
			<b>{indexdata}. </b>
            {datas}
			</div>
            <div className="sortablecontainer-module-edit-button menu-mgmt-sec">
            <button onClick={this.editsinglefield.bind(this,Index)} > <i className="ft-edit-3" aria-hidden="true"></i></button>
            <button onClick={this.deletesinglefield.bind(this,Index, this.state.field_ids[Index])}> <i className="fa fa-trash" aria-hidden="true"></i></button>
            </div>
            </li></div> );

      }
      );
      return formlayout;
      }
  }
  getdeleteall = () =>{
      this.setState({showlayout:false,name_arr:[],form_input_type_arr:[],form_input_type_value_arr:[],field_priorty_arr:[],field_tooltip_arr:[],field_required_arr:[],select_options_arr:[],status_option_arr:[]});
  }

  handlefieldTypeChange(event) {   
	this.setState({module_type:[event],module_type_value:event.value})
}

handleStatusChange = (event) => {        
	this.setState({status_type:[event],status_type_value:event.value});	
	
}

onChangeHandler=event=>{

	let reader = new FileReader();
	const file = event.target.files[0];
	reader.onloadend = () => {
	this.setState({
		image_name: file.name,
		image_preview: reader.result,
		image: file,
		image_name_single:''
	});
	};
	reader.readAsDataURL(file);

}

  render() {

	const navigate = this.props.navigate;
	console.log(this.state.module_type,'module_type');
  const {selectedOption,showlayout,login_type, image_name_single} = this.state;
			let preview = '';
		
			if (image_name_single !=='') {
				const imagepre = mediaUrl+'/'+'shortcuticon/'+this.state.image_name_single;
				preview = <img className="img_class" src={imagepre} alt="" width={100} height={100}/>;
			}

			if(this.state.image_preview!==''){
					preview = <img className="img_class" src={this.state.image_preview} alt="" width={100} height={100}/>;
			}
  return (
   <div className="wrapper"> 
    <Header />
    <MenuSidebar currentpage="shortcuticon" />  
    	<div className="content">	
    		<div className="content-wrapper">
			<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
    			<div className="success_message"></div>
				<div className="back-btn-lux">
					<a onClick={() => navigate(-1)}>Back</a>    
					</div> 
					<div className="title">
						<h4>Edit {this.state.login_type.value} shortcut icon</h4>
					</div>
    			<div className="content-body menu-mgmt-body">
  			
        			<div className="builderpage-module-pagewrapper">
                <section className="builderpage-module-section">
				{this.state.name_arr.length > 0 && <> {(this.state.showlayout == true)?<> <h2 className="typography-module-title">{this.state.login_type_value} Menus <div className="sortablecontainer-module-delete-btn"><button onClick={this.getdeleteall}>Delete All</button></div></h2>
				
                       <div className="sortablecontainer-module-wrapper">
                         <ul>
                           {this.getformlayout()}
                         </ul>
                         
                      </div></>:''}</>}
                  </section>
				  <form className="BuilderPage-module-form builderpage-module-section" >

					
						{/* <div className="form-group">					
						<label>User Type:</label>
						<Select 
						value={login_type?login_type:{ value: 'User', label: 'User'}}
						options={lang.common.login_type} 
						onChange={this.handleChange}
						isDisabled={true}
						
						/>
						</div> */}
					
						<h2 className="typography-module-title">Add Menu</h2>
						<div className="form-group">
						<label>Menu<span class="required" style={{ color: "red" }} > * </span>:</label>
						<input type="text" name="name" className="form-control" value={this.state.name} onChange={this.handleInputChange} autoComplete="off" />
						<div className="error_name"></div>
						</div>
						<div className="form-group">
						<label>Link To<span class="required" style={{ color: "red" }} > * </span>: </label>
						{login_type.value=='Merchant'&&
						<Select 
							value={this.state.module_type!=''?this.state.module_type:'Choose'}
							options={lang.common.shortcuticon_options} 
							onChange={this.handlefieldTypeChange.bind(this)}
							isSearchable={false}
						/>}
						{(login_type.value=='Broker' || login_type.value=='SalesPerson' || login_type.value=='Investor' || login_type.value=='Company' || login_type.value=='Lender')?
						<Select 
						value={this.state.module_type!=''?this.state.module_type:'Choose'}
						options={lang.common.shortcuticon_options} 
						onChange={this.handlefieldTypeChange.bind(this)}
						isSearchable={false}
						/>:''}
						
						<div className="error_module_type_value"></div>
						</div>
						

						<div className="form-group">
						<label>Image:</label>
						<div className="choose-file">
						{this.state.isHidden && <div className="image_success"></div>}
						<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
						</div>
						{preview!== '' && preview}
						{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
						<div className="errorloaderimage"></div>  
						</div> 				
						
						<div className="form-group">
							<label>Tooltip<span class="required" style={{ color: "red" }} > * </span>:</label><br></br>
							<input type="input" id="tooltip" name="tooltip" className="form-control"  value={this.state.tooltip} onChange={this.handleInputChange} />
							<div className="error_tooltip"></div>
						</div>					
						
						<div className="form-group">
							<label>Priority<span class="required" style={{ color: "red" }} > * </span>: </label>
							<input type="text" name="priorty" className="form-control" value={this.state.priorty}  onChange={this.handleInputChange} autoComplete="off" />
							<div className="error_priorty"></div>
						</div>

						<div className="form-group">
							<label>Status <span class="required" style={{ color: "red" }} > * </span>: </label>							
							<Select 
								value={this.state.status_type!=''?this.state.status_type:'Choose'}
								options={lang.common.status_option} 
								onChange={this.handleStatusChange.bind(this)}
								isSearchable={false}
							/>							
							<div className="error_status_type_value"></div>							
						</div>

						<div className="submit-button">
							<span className="btn btn_orange btn_minwid login_submit animate-btn2" id="addbutton" onClick={this.Addtolayout}>Add</span>
						</div>
						
						</form>
          </div>
		  <div className="menu-mgmt-sub-btn">	
						<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}  onClick={this.handleFormSubmit.bind(this)} > {this.state.Loading ===true &&
											<span className="load-data"></span> 
											}Submit
						</button>
						</div>
  			</div>	
			</div>
  		</div>	
  	</div>
  </div>
  );
  }
  }

export default (withRouter(Edit));